const formHelper = {
	el: {
		checkbox: document.querySelectorAll('.js-checkbox')
	},

	fxFormToJson(el) {
		const indexedArray = {}
		for (let i = 0; i < el.length; i++) {
			if (el[i].value) {
				if (['checkbox', 'radio'].indexOf(el[i].type) > -1 && el[i].checked || ['checkbox', 'radio', 'file', 'button'].indexOf(el[i].type) === -1)
					indexedArray[el[i].name] = el[i].value
			}
		}
		return indexedArray
	},

	fxInputEvents() {
		this.el.checkbox.forEach(el => el.addEventListener('change', e => e.currentTarget.value = e.currentTarget.checked ? 'true' : 'false'))
	},

	init() {
		this.fxInputEvents()
	}
}

formHelper.init()