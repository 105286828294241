/* global axios*/
/*eslint-disable no-unused-vars*/
const http = {
	fxPreloader(el) {
		// Add a request interceptor
		axios.interceptors.request.use(config => {
			// Do something before request is sent
			const button = {
				el: el.querySelector('.js-preloader'),
				height: el.querySelector('.js-preloader').offsetHeight
			}
			button.el.setAttribute('disabled', true)
			button.el.classList.add('btn--loading')
			button.el.style.height = `${button.height}px`
			const spinner = document.createElement('div')
			spinner.classList.add('btn--loading-spinner')
			el.querySelector('.btn--loading').appendChild(spinner)

			return config
		}, error => {
			return Promise.reject(error)
		})

		// Add a response interceptor
		axios.interceptors.response.use(response => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			const button = el.querySelector('.js-preloader')
			button.removeAttribute('disabled')
			button.classList.remove('btn--loading')
			button.querySelector('.btn--loading-spinner').outerHTML = ''

			return response
		}, error => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error)
		})
	},

	fxPost(endpoint, formData, callback) {
		return axios.post(endpoint, formData)
			.then(result => {
				callback(result)
				return result
			})
			.catch(error => callback({ ...error }))
	},

	fxGet(endpoint, callback) {
		return axios.get(endpoint)
			.then(result => {
				callback(result)
				return result
			})
			.catch(error => callback({ ...error }))
	}
}