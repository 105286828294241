/* global http formFilter*/
const loadMore = {
	el: {
		btnLoadMore: document.querySelector('.js-load')
	},

	fxLoadMore () {
		this.el.btnLoadMore.addEventListener('click', (e) => {
			http.fxGet(e.currentTarget.dataset.endpoint, callback)
		})

		const callback = info => {
			formFilter.fxRenderContent(info)
		}
	},

	fxInitialLoad () {
		document.addEventListener('DOMContentLoaded', () => {
			http.fxGet(this.el.btnLoadMore.dataset.endpoint, callback)
		})
		
		const callback = info => {
			formFilter.fxRenderContent(info)
		}
	},

	init () {
		this.fxLoadMore()
		this.fxInitialLoad()
	}
}

loadMore.init()