const detectIE = {

	el: {
		body: document.querySelector('body')
	},

	vars: {
		msie: window.navigator.userAgent.indexOf('MSIE'), //IE6-10
		trident: window.navigator.userAgent.indexOf('Trident/') // IE11
	},

	fxCheckIE() {
		if (this.vars.msie > 0) {
			this.el.body.classList.add('old-browser')
			this.el.body.insertAdjacentHTML('afterbegin', '<span class="old-browser__message">Navegador não suportado. Clique&nbsp;<a target="_blank" href="http://outdatedbrowser.com/pt">aqui</a>&nbsp;para actualizar</span>')
		}
		if (this.vars.trident > 0) {
			this.el.body.classList.add('is-ie')
			if (window.NodeList && !NodeList.prototype.forEach)
				NodeList.prototype.forEach = Array.prototype.forEach
		}
	},

	init() {
		this.fxCheckIE()
	}
}

detectIE.init()