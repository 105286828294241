const hoverToTouch = {
	el: {
		body: document.body
	},
	events() {
		this.el.body.addEventListener('touchstart', () => {})
	},

	init() {
		this.events()
	}

}

hoverToTouch.init()