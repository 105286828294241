/* global Bouncer */
const formValidation = {
	el: {
		html: document.documentElement,
		body: document.body,
		inputNumber: document.querySelectorAll('[type=number]:not(.js-range-date)'),
		pastDate: document.querySelectorAll('.js-past-date'),
		futureDate: document.querySelectorAll('.js-future-date'),
		rangeDate: document.querySelectorAll('.js-range-date')
	},

	fxInitBouncer(form) {
		new Bouncer(form.dataset.selector, {
			disableSubmit: true,
			fieldClass: 'form__error', // Applied to fields with errors
			errorClass: 'form__error-message', // Applied to the error message for invalid fields
			fieldPrefix: 'form__field--', // If a field doesn't have a name or ID, one is generated with this prefix
			errorPrefix: 'form__error--', // Prefix used for error message IDs,
			// Override default Patterns
			patterns: {
				email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/i,
				url: /^(http(s?):\/\/)(?!www | www\.)[a-z0-9_-]+\.+[a-z0-9./%&=?_:;-]+$/i
			},
			customValidations: {
				compareFields: field => {
					const selector = field.dataset.equalto,
						otherField = field.form.querySelector(selector)

					if (!selector || !otherField) return false

					return otherField.value !== field.value
				},

				nif: field => {
					if (field.classList.contains('js-nif')) {
						let char = field.value

						if (char.length > 0)
							if (char += '', !['1', '2', '3', '5', '6', '8'].includes(char.substr(0, 1)) && !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(char.substr(0, 2))) return !1

						let d, b = 9 * char[0] + 8 * char[1] + 7 * char[2] + 6 * char[3] + 5 * char[4] + 4 * char[5] + 3 * char[6] + 2 * char[7],
							c = b - 11 * parseInt(b / 11)

						return !(d = 1 == c || 0 == c ? 0 : 11 - c, char[8] == d)
					}
				},

				pastDate: field => field.classList.contains('js-past-date') && field.value > field.getAttribute('max'),
				futureDate: field => field.classList.contains('js-future-date') && field.value < field.getAttribute('min')
			},

			messages: {
				missingValue: {
					checkbox: form.dataset.msgRequired,
					radio: form.dataset.msgRequired,
					select: form.dataset.msgRequired,
					default: form.dataset.msgRequired
				},
				patternMismatch: {
					email: form.dataset.msgInvalidFormat,
					url: form.dataset.msgInvalidFormat,
					number: form.dataset.msgInvalidFormat,
					color: form.dataset.msgInvalidFormat,
					date: form.dataset.msgInvalidFormat,
					time: form.dataset.msgInvalidFormat,
					month: form.dataset.msgInvalidFormat,
					default: form.dataset.msgInvalidFormat
				},
				outOfRange: {
					over: `${form.dataset.msgMaxFormat} {max}`,
					under: `${form.dataset.msgMinFormat} {min}`
				},
				wrongLength: {
					over: `${form.dataset.msgMaxlengthFormat} {maxLength}`,
					under: `${form.dataset.msgMinlengthFormat} {minLength}`
				},
				compareFields: () => form.dataset.msgCompare,
				nif: () => form.dataset.msgInvalidFormat,
				pastDate: () => `${form.dataset.msgMaxFormat} ` + new Date().toJSON().split('T')[0],
				futureDate: () => `${form.dataset.msgMinFormat} ` + new Date().toJSON().split('T')[0]
			}
		})
	},

	fxValidate(form) {
		this.fxInitBouncer(form)
		return form.checkValidity()
	},

	fxSetValidationAttributes() {
		this.el.pastDate.length ? this.el.pastDate.forEach(el => el.setAttribute('max', new Date().toJSON().split('T')[0])) : ''
		this.el.futureDate.length ? this.el.futureDate.forEach(el => el.setAttribute('min', new Date().toJSON().split('T')[0])) : ''
		this.el.inputNumber.length ? this.el.inputNumber.forEach(el => el.setAttribute('min', 0)) : ''
		if (this.el.rangeDate.length) {
			this.el.rangeDate.forEach(el => {
				el.setAttribute('min', '1900')
				el.setAttribute('max', new Date().getFullYear())
			})
		}
	},

	init() {
		this.fxSetValidationAttributes()
	}
}

formValidation.init()