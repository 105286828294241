/* global http*/
const formFilter = {
	el: {
		html: document.documentElement,
		body: document.body,
		filterForm: document.querySelector('.js-form-filter'),
		filterResults: document.querySelector('.filter__items'),
		btnLoadMore: document.querySelector('.js-load')
	},

	fxFormEvents(selector) {

		const form = document.querySelector(selector)

		form.addEventListener('change', e => {
			e.preventDefault()
			let endpoint = e.currentTarget.action,
				finalEndpoint = endpoint.concat('?localidade=', e.target.value, '&page=1')

			http.fxGet(finalEndpoint, callback)
		})

		const callback = info => {
			formFilter.fxRenderContent(info)
			if (info.status === 200) {
				this.el.filterResults.innerHTML = ''
				this.fxRenderContent(info)
			}
			//-handle error
			if (info.response) {
				if (info.response.status === 409)
					console.log('error')
					//-formHelper.fxShowMessage(form, form.dataset.msgConflict, 409)

				if (info.response.status === 500)
					console.log('error')
					//-formHelper.fxShowMessage(form, form.dataset.msgServer, 500)
			}
		}
	},

	fxRenderContent (response) {
		const data = response.data.items
		data.forEach((i) => {
			let item = `<li class='filter__item'>
							<div class='filter__item-title'>${i.name}</div>
							<div class="filter__item-address">${i.address}</div>
							<div class="filter__item-zip">${i.zip}</div>
							<div class="filter__item-country">Portugal</div>
							<div class="filter__item-number">Telefone fixo: <span>${i.tel}</span></div>
						</li>`
			this.el.filterResults.innerHTML += item
		})

		this.el.btnLoadMore.style.display = response.data.loadmore === true ? 'block' : 'none'
		let endpoint = this.el.btnLoadMore.dataset.endpoint,
			fields = endpoint.split('?'),
			url = fields[0],
			finalEndpoint = url.concat('?localidade=', response.data.localidade, '&page=', response.data.page + 1)
		this.el.btnLoadMore.dataset.endpoint = finalEndpoint
	},

	init() {
		if (this.el.filterForm) {
			this.fxFormEvents('.js-form-filter')
			if (!this.el.filterForm.querySelector('.js-preloader')) return
			http.fxPreloader(this.el.filterForm)
		}
	}
}

formFilter.init()